$(document).ready(function () {
    // Initialize the multiselect-types
    $('.multiselect-type').each(function(index) {
        // Find the first select element within the current .multiselect-type
        let firstSelect = $(this).find('select:first');

        if (firstSelect.length > 0) {
            // Get the initialization values from the data attributes
            let id = firstSelect.attr('id');
            let placeHolderLeft = $(`#${id}`).data('placeholder-left');
            let placeHolderRight = $(`#${id}`).data('placeholder-right');
            initializeMultiSelect(`#${id}`, placeHolderLeft, placeHolderRight);
        }
    });
});