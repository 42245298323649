/* global $ */

"use strict";

/**
 * Custom JS
 */
$(document).ready(function () {
    /**
     * code to trigger the confirmation modal
     */
    $('.is-alterify-cancel-confirm').on('click', function (e) {
        e.preventDefault();
        let title = $(this).data('title');
        let message = $(this).data('message');
        let proceedBtnTitle = $(this).data('process-btn-label');
        let cancelBtnTitle = $(this).data('cancel-btn-label');

        // Save a reference to the clicked element
        var clickedElement = $(this);

        initConfirm(title, message, false, false, proceedBtnTitle, cancelBtnTitle, function (closeEvent) {
            // Use the saved reference to find and submit the form
            clickedElement.closest('form').submit();
        });
    });


    /**
     * code to trigger the hiding of the confirmation modal
     */
    $(document).ready(function () {
        $('.message .delete').on('click', function (e) {
            e.preventDefault();
            $(this).parent().remove();
        });
    });

    /**
     * Function to close the snack notifications shown after successful operations
     */
    $('.message .delete').on('click', function (e) {
        e.preventDefault();
        $(this).parent().remove();
    });

    /**
     * Function to copy information to the clipboard
     */
    $(document).on('click', '.copy-text-to-clipboard', function (e) {
        e.preventDefault();
        let elementToCopy = $(this);
        let copiedText;
        if (typeof elementToCopy.data('text-to-copy') !== 'undefined') {
            copiedText = elementToCopy.data('text-to-copy');
        } else {
            copiedText = elementToCopy.data('hint');
            elementToCopy.attr('data-hint', 'Copied to Clipboard');
        }

        // Copy the text inside the text field
        navigator.clipboard.writeText(copiedText);

        // Update the tooltip after 5 seconds
        setTimeout(function () {
            elementToCopy.attr('data-hint', copiedText);
        }, 5000);
    });
});
